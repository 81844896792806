/* Global */
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* Cartes et animation */
.card, .purpose-card {
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

/* Zoom et ombre à l'hover */
.card:hover, .purpose-card:hover {
  transform: scale(1.05); /* Zoom léger */
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.2); /* Ombre plus prononcée */
  background-color: #f9f9f9; /* Légère modification de fond */
}

/* Boutons */
.bg-green-500 {
  background-color: #48bb78;
}

.bg-green-700 {
  background-color: #2f855a;
}

.bg-gray-700 {
  background-color: #4a5568;
}

.text-white {
  color: #ffffff;
}

.hover\:bg-gray-600:hover {
  background-color: #2d3748;
}

.hover\:bg-green-700:hover {
  background-color: #2f855a;
}

/* Interrupteur plus petit */
.switch.small {
  position: relative;
  display: inline-block;
  width: 40px;  /* Taille réduite */
  height: 20px;  /* Taille réduite */
}

.switch.small input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 14px;
  width: 14px;
  left: 3px;
  bottom: 3px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #4CAF50;
}

input:checked + .slider:before {
  transform: translateX(20px); /* Ajustement pour l'interrupteur réduit */
}

.slider.round {
  border-radius: 20px;
}

.slider.round:before {
  border-radius: 50%;
}

.fixed {
  position: fixed;
  bottom: 16px;
  left: 16px;
  right: 16px;
  z-index: 50;
  transition: transform 0.5s ease-out;
}

.shadow-lg {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.1);
}

.rounded-lg {
  border-radius: 0.5rem;
}

.p-4 {
  padding: 1rem;
}

.space-x-4 > * + * {
  margin-left: 1rem;
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  opacity: 0.9;
}

.border {
  border-width: 1px;
  border-color: #e2e8f0; /* Gris clair */
}


.bg-gray-800 {
  background-color: #2d3748; /* Gris foncé */
}

.text-white {
  color: white;
}

.p-4 {
  padding: 1rem;
}

.bg-green-500 {
  background-color: #48bb78; /* Vert acceptation */
}

.bg-red-500 {
  background-color: #f56565; /* Rouge refus */
}

button {
  transition: background-color 0.3s ease;
}

button:hover {
  opacity: 0.9;
}

