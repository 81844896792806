/* Sidebar */
.sidebar {
    width: 240px;
    background-color: #1E1E1E;
    color: white;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    padding-top: 20px;
}

.sidebar-logo {
    display: block;
    margin: 0 auto;
    width: 80%;
    height: auto;
}

.sidebar ul {
    list-style: none;
    padding: 0;
    margin-top: 20px;
}

.sidebar ul li {
    padding: 15px;
    color: white;
    cursor: pointer;
    display: flex;
    align-items: center;
}

.sidebar ul li:hover {
    background-color: #333;
}

.sidebar-icon {
    margin-right: 10px;
}

/* Main Dashboard Content */
.dashboard-content {
    margin-left: 240px;
    padding: 20px;
    width: calc(100% - 240px);
}

.dashboard-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #2C3E50;
    padding: 10px;
    color: white;
    border-radius: 8px;
}

.header-left h1 {
    margin: 0;
}

.header-right {
    display: flex;
    align-items: center;
}

/* Card Container */
.card-container {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    margin-bottom: 30px; /* Espace en bas des cards */
}

/* Cards container */
.card {
    background-color: #ffffff; /* Couleur blanche pour un look propre */
    padding: 20px;
    border-radius: 16px; /* Arrondi des coins */
    width: 100%; /* Assurez-vous que chaque card prend toute la largeur disponible dans la grid */
    text-align: center;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease; /* Ajout d'une transition fluide */
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1); /* Légère ombre pour donner de la profondeur */
    margin: 15px; /* Espacement autour des cards */
}

.card:hover {
    transform: translateY(-10px); /* Légère élévation au survol */
    box-shadow: 0px 8px 24px rgba(0, 0, 0, 0.2); /* Ombre plus marquée au survol */
}

.circular-progress-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-bottom: 15px; /* Ajout d'un espace sous la barre de progression */
}

.progress-text {
    position: absolute;
    font-size: 22px;
    font-weight: bold;
    color: #333; /* Couleur sombre pour une meilleure lisibilité */
}

.card-title {
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #00FF00; /* Couleur verte pour les titres */
}

.card-description {
    font-size: 16px;
    color: #666; /* Gris clair pour les descriptions */
    margin-bottom: 10px;
}

.card-icon {
    font-size: 40px;
    color: #00FF00; /* Couleur verte pour les icônes */
    margin-bottom: 10px;
}

@keyframes blink {
    0% {
        background-color: transparent;
    }
    50% {
        background-color: #ef4747;
    }
    100% {
        background-color: transparent;
    }
}

.new-project {
    animation: blink 2s ease-in-out infinite alternate;
    background-color: #e37979;
    color: white;
}

.deleted-row {
    background-color: #f5f5f5;  /* Griser la ligne */
    text-decoration: line-through;  /* Barrer la ligne */
    pointer-events: none;  /* Désactiver les interactions */
    opacity: 0.5;  /* Ajouter un effet de transparence */
}

/* Forcer la visibilité et le positionnement du modal */
.MuiModal-root {
    z-index: 1300 !important;
    position: fixed !important;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* S'assurer que le contenu du modal ne dépasse pas */
}

.MuiBackdrop-root {
    z-index: 1290; /* Assurez que le backdrop a un z-index inférieur au modal */
}

.MuiBox-root {
    z-index: 1300 !important;
    position: relative;
}

body, html {
    overflow: visible !important;
}

@keyframes blink-orange {
    0% {
        background-color: #FFB347;
    }
    50% {
        background-color: #ffcc80; /* Une teinte plus claire d'orange */
    }
    100% {
        background-color: #FFB347;
    }
}

.blinking-row {
    animation: blink-orange 1.5s infinite;
}
