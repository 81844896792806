.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    animation: fadeIn 0.3s ease;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.3);
    position: relative;
    max-width: 400px;
    width: 100%;
    transition: all 0.3s ease;
}

.modal-dark {
    background-color: #1a202c; /* Couleur sombre pour le mode dark */
    color: white;
}

.modal-light {
    background-color: #f7fafc;
    color: #2d3748;
}

.modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    margin-bottom: 1rem;
    text-align: center;
}

.modal-form {
    display: flex;
    flex-direction: column;
}

.input-group {
    margin-bottom: 1rem;
}

.input-wrapper {
    display: flex;
    align-items: center;
    background-color: #e2e8f0;
    padding: 0.5rem;
    border-radius: 5px;
}

.input-icon {
    margin-right: 10px;
    color: #718096;
}

.modal-input {
    border: none;
    background: none;
    outline: none;
    flex-grow: 1;
}

.modal-input::placeholder {
    color: #a0aec0;
}

.modal-btn {
    background-color: #3182ce;
    color: white;
    border: none;
    padding: 0.75rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.modal-btn:hover {
    background-color: #2b6cb0;
}

.modal-close {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 1.25rem;
    cursor: pointer;
    color: #a0aec0;
}

.error {
    color: #e53e3e;
    font-size: 0.875rem;
    text-align: center;
    margin-bottom: 1rem;
}

@keyframes fadeIn {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

.checkbox-label {
    font-size: 0.85rem; /* Réduction de la taille de police */
    color: #4a5568; /* Couleur douce pour rendre le texte moins imposant */
}

.modal-dark .checkbox-label {
    color: #cbd5e0; /* Ajuste la couleur pour le mode sombre */
}

.checkbox-group {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
}

.checkbox-group div {
    display: flex;
    align-items: center;
}

.checkbox-input {
    margin-right: 5px;
}

.forgot-password-link {
    margin-left: auto;
    font-size: 0.875rem;
    color: #3182ce;
    text-decoration: none;
    transition: color 0.3s ease;
}

.forgot-password-link:hover {
    color: #2b6cb0;
}

