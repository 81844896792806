.sidebar {
    width: 240px;
    background: linear-gradient(180deg, #2c5364, #203a43);
    color: #fff;
    padding: 20px;
    height: 100vh;
    position: fixed;
    transition: width 0.3s ease;
}

.sidebar ul {
    list-style: none;
    padding: 0;
}

.sidebar ul li {
    margin: 15px 0;
    cursor: pointer;
    display: flex;
    align-items: center;
    transition: all 0.3s ease;
    color: #fff;
    padding: 10px;
    border-radius: 10px;
}

.sidebar ul li:hover {
    background-color: #3a506b;
    transform: translateX(5px);
}

.sidebar-icon {
    margin-right: 15px;
    color: #ffca28; /* Couleur des icônes */
}

.logo-container {
    text-align: center;
    margin-bottom: 30px;
}

.sidebar-logo {
    width: 100%;
    height: auto;
}

.active-section {
    background-color: #1e3548;
    border-left: 4px solid #ffca28; /* Barre à gauche pour indiquer la sélection */
}
